import { Component, OnInit, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { takeWhile } from 'rxjs/operators'


export enum KEY_CODE {
  RIGHT_ARROW = 39,
  LEFT_ARROW = 37
}

@Component({
  selector: 'app-document',
  templateUrl: './modal-document.component.html',
  styleUrls: ['./modal-document.component.scss']
})
export class ModalDocumentComponent implements OnInit {
  public data: any;
  private isAlive: boolean = true
  public index: number;



  constructor(
      private nbDialogRef: NbDialogRef<ModalDocumentComponent>,
    ) { }


    @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    console.log(event);

    if (event.keyCode === KEY_CODE.RIGHT_ARROW) {
      this.next();
    }

    if (event.keyCode === KEY_CODE.LEFT_ARROW) {
      this.prev();
    }
  }


  ngOnInit(): void {
    console.log(this.data)

    this.index = this.data.index;
    console.log(this.index)

  }

  close(): void {
    this.nbDialogRef.close();
  }

  next(): void {
    console.log(this.index)
    if((this.data.array.length - 1) === this.index){
      this.index = 0;
    }else{
      this.index = this.index + 1
    }
  };

  prev(): void{
    console.log(this.index)
    if(this.index === 0){
      this.index = this.data.array.length - 1;
    }else{
      this.index = this.index - 1
    }
  }


  onClickImage(index): void{
    this.index = index
  }

  onClickDownload(file){
    console.log(file)

    var el = document.createElement('a');
    el.setAttribute('href', file.url)
    el.setAttribute('download', file.nome);
    document.body.appendChild(el);
    el.click();
    document.body.removeChild(el)
  }

}
