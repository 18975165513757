import { ActivatedRoute, Router } from "@angular/router"
import { NbDialogService } from "@nebular/theme"
import { DataUser } from "../models/user"
import { AlertService } from "./alert.service"
import { AuthService } from "./auth.service"
import { UserService } from "./user.service"
import { Injectable } from '@angular/core'


@Injectable({
  providedIn: 'root',
})


export class PermissionsService {

  currentUserData: DataUser

  roles: Array<any> = [
    {
      roleName: 'Profissional',
      rotas: [
        'home',
        'exams',
        'painelExames',
        'profissional-dados',
      ]
    },
    {
      roleName: 'Clinica',
      rotas: [
        'home',
        'exams',
        'painelExames',
        'atendimento',
        'atendimento-triagem',
        'clinica-dados',
        'clinica-profissionais',
      ]
    },
    {
      roleName: 'Gestor',
      rotas: [
        'home',
        'exams',
        'painelExames',
        'atendimento',
        'atendimento-triagem',
        'clinics',
        'professionals',
        'exams',
        'configuracoes',
        'lista-exames',
        'editar-exames',
        'cadastro-exames',
      ]
    }
  ]
  constructor(
    private userService: UserService,
    private authService: AuthService,
    private alert: AlertService,
    private router: Router,
    private route: ActivatedRoute,


  ) { }


  checkIfRouteIsAllowed = () => {

    const currUser = this.authService.getDataUser();
    if (!currUser) {
      this.router.navigate(['/login'])
    }
    const currRole = this.roles.filter((role) => role.roleName === currUser.tipoUsuario);

    // console.log(this.router.url)
    // console.log(this.router.url.split('/'));
    // console.log(this.router.url.split('/')[1]);

    const allowedRoutes = currRole[0].rotas.filter((rota) => rota === this.router.url.split('/')[1]);
    // console.log(allowedRoutes)
    if (allowedRoutes.length === 0) {
      this.alert.error('Você não tem permissão para acessar esta página');
      this.router.navigate(['home'])
    }
  }



}
