import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { ServerService } from './server.service'
import { catchError } from 'rxjs/operators'
import { monitoringPanel } from '../models/monitoring-panel'
import { IResponse } from '../models/response'

@Injectable({
  providedIn: 'root',
})

export class ClinicService {
  constructor(private http: HttpClient, private server: ServerService) { }


  // Busca endereço pelo CEP:
  getEnderecoByCep(cep: String) {
    return this.http.get<IResponse>(this.server.url(`/Endereco/${cep}`));
  }


  getData() {
    return this.http
      .get<any>(this.server.url(`/clinica`))
      .pipe(catchError(this.server.handleError))
  }

  getDataById(id) {
    return this.http
      .get<any>(this.server.url(`/clinica/${id}`))
      .pipe(catchError(this.server.handleError))
  }

  postClinic(data) {
    return this.http.post<IResponse>(this.server.url(`/clinica`), data)
  }

  putClinic(id, data) {
    console.log(data)
    return this.http.put<IResponse>(this.server.url(`/clinica/${id}`), data)
  }


  getCurrentClinicByName(clinicName: string) {
    return this.http.get<IResponse>(this.server.url(`/Clinica?nome=${clinicName}`));
  }

  getProfessionalListByClinicUid(usuarioClinicaId: String) {
    return this.http.get<IResponse>(this.server.url(`/Profissional?usuarioClinicaId=${usuarioClinicaId}&ativo=true`)).toPromise();
  }
}
