import { NgModule } from "@angular/core";
import { ButtonComponent } from "./button.component";
import { NbEvaIconsModule } from "@nebular/eva-icons";
import { NbIconModule, NbButtonModule } from "@nebular/theme";
import { CommonModule } from "@angular/common";

@NgModule({
  declarations: [ButtonComponent],
  imports: [NbButtonModule, NbEvaIconsModule, NbIconModule, CommonModule],
  exports: [ButtonComponent],
})

export class ButtonModule { }
