<div class="border w-100">
  <div class="image-wrapper d-flex align-items-center">
    <img
      src="{{ data.array[index].url }}"
      alt="{{ data.array[index].nome }}"
      class="image-display img-fluid"
      onerror="this.src='https://cdn2.iconfinder.com/data/icons/basic-office-snippets/170/Basic_Office-6-512.png'"
    />
  </div>


</div>
<div class="d-flex align-items-center imagem-list">
  <div
    *ngFor="let item of data.array; let i = index"
    class="imagem-item"
    [ngClass]="i === index ? 'active' : null"
    (click)="onClickImage(i)"
  >
    <img

      src="{{ item.url }}"
      alt="{{ item.nome }}"

      onerror="this.src='https://cdn2.iconfinder.com/data/icons/basic-office-snippets/170/Basic_Office-6-512.png'"
    />
  </div>
</div>

<div class="close-btn">
  <button type="button" class="btn btn-red" shape="round" (click)="close()">
    <span> Fechar </span>
    <nb-icon icon="close-outline"></nb-icon>
  </button>
</div>
