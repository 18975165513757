import { EventEmitter, Component, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-button",
  templateUrl: "./button.component.html",
  styleUrls: ["./button.component.scss"],
})
export class ButtonComponent implements OnInit {
  @Input() text: string = 'Título';
  @Input() classParent: string = '';
  @Input() backColor: string = '#c4c4c4';
  @Input() textColor: string = '#ffffff';
  @Input() icon: string = '';
  @Output() clickEmit = new EventEmitter();

  constructor() { }

  ngOnInit() { }

  emitClick() {
    this.clickEmit.emit('');
  }
}
